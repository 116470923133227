import mission_pic from '../../image/about/mission1.jpeg';
import achiv_pic from '../../image/about/achiv1.jpeg';
import collab_pic from '../../image/about/collab.jpeg';

function Milestone() {
  return (
    <>
      <div className="milestone-sec" id="milestone">
        <div className="milestone-heading">
          <h2>milestone</h2>
        </div>
        <div className="milestone-content">
          <div className="my-mission mile-box">
            <div className="center-design">
              <div className="mile-pic">
                <img src={mission_pic} alt="mission_pic" />
              </div>
              <div className="mile-content mile-content-color">
                <h3 className="mile-bullet">My Mission</h3>
                <p>
                  My mission is to provide clients with high-quality services in
                  an eco-friendly environment and phenomenal beauty services in
                  a relaxing and welcoming environment that leaves our clients
                  feeling pampered and confident. By providing personalized
                  services tailored to our clients' unique needs and
                  preferences, we're dedicated to helping our clients achieve
                  their beauty goals.
                </p>
              </div>
            </div>
          </div>

          <div className="my-Achivements mile-box">
            <div className="center-design">
              <div className="mile-pic">
                <img src={achiv_pic} alt="achiv_pic" />
              </div>
              <div className="mile-content mile-content-color">
                <h3 className="mile-bullet">My Achivements</h3>
                <p>
                  South India Fashion Awards (SIFA) is an annual celebration of
                  Fashion and Style where they recognize and award the best
                  talents in the Fashion and Entertainment industry, including
                  Make-up artists and Hair Stylists. And I was previlaged to Win
                  the Best Hairstylist in SIFA 2018. It is not just a
                  recognition of skill, but a celebration of creativity and
                  dedication. With each strand meticulously styled, our
                  hairstylist has woven a masterpiece that complements and
                  completes the South Indian fashion narrative, leaving an
                  indelible mark on the industry's canvas."
                </p>
              </div>
            </div>
          </div>

          <div className="my-collaboration mile-box">
            <div className="center-design">
              <div className="mile-pic">
                <img src={collab_pic} alt="collab_pic" />
              </div>
              <div className="mile-content mile-content-color">
                <h3 className="mile-bullet">My Collaboration</h3>
                <div className="mile-para">
                  <p>
                    Working with Nayantara has been a transformative experience,
                    blending creativity and collaboration seamlessly. Our
                    partnership transcends conventional beauty standards,
                    crafting iconic looks that resonate on screen. Each project
                    is a canvas where tradition meets innovation, fueled by
                    mutual respect and a shared commitment to excellence.
                    Together, we've redefined beauty in Indian cinema, leaving
                    an indelible mark with every brushstroke.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Milestone;
