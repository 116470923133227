// import { CgProfile } from 'react-icons/cg';
import logo from '../../image/header/pl-white-sqr.png';
// import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';

function Nav({ setIsAdmin, setPassword }) {
  // const navigateToHome = useNavigate();
  return (
    <>
      {/* nav bar */}
      <nav className="nav">
        <div className="logo-box">
          <img src={logo} alt="header_background" />
        </div>

        {/* replace by router */}
        <div className="menu">
          <a href="#header"> Home </a>
          <a href="#testimonials">Testimonial</a>
          <a href="#services">Services</a>
          <a href="#about">About</a>
          <a href="#milestone">Milestone</a>
          <a href="#contact">Contact</a>
          {/* <Link to="services">Services</Link>
          <Link to="testimonials">Testimonial</Link>
          <Link to="contact">Contact</Link> */}
        </div>
        {/* <div
          className="logs"
          style={{ cursor: 'pointer', zIndex: '9' }}
          onClick={() => {
            console.log('Logout');
            setIsAdmin(false);
            setPassword('');
            navigateToHome('/home');
          }}
        > */}
        {/* <CgProfile size={35} /> */}
        {/* <i class="fa-solid fa-user-plus"></i>
        </div> */}
      </nav>
    </>
  );
}

export default Nav;
