import pro1 from '../../image/about/pro1.jpeg';

function About() {
  return (
    <>
      <div className="about-section" id="about">
        <div className="about-me">
          <div className="about">
            <p>A</p>
            <p>B</p>
            <p>O</p>
            <p>U</p>
            <p>T</p>
          </div>
          <div className="ME">
            <p>M</p>
            <p>E</p>
          </div>
          <div className="media-profile-pic">
            <img src={pro1} alt="pinky-pic" />
          </div>
        </div>
        <div className="profile-pic">
          <div className="border"></div>
          <img src={pro1} alt="pinky-pic" />
        </div>
        <div className="about-me-des">
          <p>“Hi There” </p>
          <p>
            Hello, my name is Pinky Lohar, and I'm delighted to share my journey
            with you. Originating from a <span>humble village in Manipur</span>,
            I found unexpected love and opportunity in Chennai during my school
            years, and I never looked back. My transformative experience began
            during a summer vacation in the 9th grade when I visited my aunt's
            residence in <span>Chennai.</span> An evening at Marina Beach
            sparked a profound sense of belonging, leading me to decide to stay.
            Initially, I assisted my aunt in her parlor, and her mentorship
            profoundly shaped my path. I owe her a debt of{' '}
            <span>gratitude for her guidance.</span>
            <br /> Gradually, through dedication and recognition, I expanded my
            horizons, collaborating with{' '}
            <span>various individuals in the film industry.</span> These
            experiences were invaluable in honing my skills. Despite the
            challenges posed by the pandemic, I took a leap of faith to
            establish myself as an <span> independent artist.</span> Although
            starting anew was daunting, I was fortunate to receive support from{' '}
            <span>friends, family, and by the grace of God.</span>
            <br /> I must express my heartfelt appreciation to my dedicated
            team, affectionately known as <span>'my girls,'</span> who have been
            my pillars of strength throughout. Reflecting on my journey, I am
            overwhelmed with <span>gratitude and joy.</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default About;
