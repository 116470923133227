function Statistics() {
  return (
    <>
      <div className="statistics-bar">
        <div className="experience">
          <h2 className="experience-years">15Y+</h2>
          <div className="statistics-title">
            <i class="fa-solid fa-arrow-up-right-dots"></i>
            <h2 className="stats-name">Experience</h2>
          </div>
        </div>
        <div className="reviews">
          <h2 className="reviews-num">10,500+</h2>
          <div className="statistics-title">
            <i class="fa-solid fa-user-pen"></i>
            <h2 className="stats-name">Reviews</h2>
          </div>
        </div>
        <div className="customers">
          <h2 className="customers-num">20,000+</h2>
          <div className="statistics-title">
            <i class="fa-solid fa-users-line"></i>
            <h2 className="stats-name">Customers</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default Statistics;
