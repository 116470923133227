function Top() {
  return (
    <>
      <a href="#header" className="scroll-top">
        &gt;
      </a>
    </>
  );
}

export default Top;
