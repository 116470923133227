import Nav from '../Reusable/Nav.js';
import woman2 from '../../image/header/headerLadycpy.png';

function Header({ setIsAdmin, setPassword }) {
  return (
    <header className="header" id="header">
      <Nav setIsAdmin={setIsAdmin} setPassword={setPassword} />
      {/* main */}
      <main className="main">
        <div className="main-line">
          <p className="greet">
            Welcome to <span className="pinky-lohar">"Pinky Lohar"</span>
          </p>
          <p className="headline">Inspiring Beauty, Inside and Out</p>
          <p className="headline-des">
            " Sometimes all we need is a little pampering to help us feel
            better. Nothing makes a woman more beautiful than the belief that
            she is beautiful. If you don&lsquo;t look good, we don&lsquo;t look
            good." - Pinky
          </p>
          <a href="#contact" className="book">
            Book an appointment →
          </a>
        </div>
        <div className="headering">
          <img src={woman2} alt="woman" />
        </div>
      </main>
    </header>
  );
}

export default Header;
