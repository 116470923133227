import Header from '../../components/Header/Header';
import Testimonials from '../../components/Testimonials/Testimonials';
import Services from '../../components/Services/Services';
import About from '../../components/About/About';
import Milestone from '../../components/About/Milestone';
import Statistics from '../Statistics/Statistics';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';
import Top from '../../components/Top/Top';

const Main = ({ setIsAdmin, setPassword }) => {
  return (
    <div>
      <Header setIsAdmin={setIsAdmin} setPassword={setPassword} />
      <Testimonials />
      <Services />
      <About />
      <Milestone />
      <Statistics />
      <Contact />
      <Footer />
      <Top />
    </div>
  );
};

export default Main;
