//////// After Team Discussion i'll apply the code  ///////////////
import bridal from '../../image/services/bridal.jpg';
import occasion from '../../image/services/occasion.jpg';
import fashion from '../../image/services/fashion.jpg';
import event from '../../image/services/event.jpg';
import effect from '../../image/services/effect.jpg';
import workshops from '../../image/services/workshops.jpg';

const film =
  'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Fcut.jpg?alt=media&token=9d49a655-ee55-45a5-8e56-c00255694377';
const makeup =
  'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Fmakeup.jpg?alt=media&token=b26aa54b-67f8-4814-9437-8fa7f2d2b1fc';

const services = [
  {
    id: 1,
    image: film,
    service: [
      { name: 'Film and TV Makeup and Hairstyling' },
      {
        servicesList: [
          // { id: 1, name: 'Short Hair Cut', price: 400 },
          // { id: 2, name: 'Long Hair Cut', price: 500 },
          // { id: 3, name: 'Bangs', price: 300 },
          // { id: 4, name: 'Cut + Blow dry', price: 600 },
        ],
      },
    ],
  },

  {
    id: 2,
    image: makeup,
    service: [
      { name: 'Personal Makeup Consultations' },
      {
        servicesList: [
          // { id: 1, name: 'Wedding Make-up', price: 600 },
          // { id: 2, name: 'Make-up & Lashes', price: 450 },
          // { id: 3, name: 'Party Glam', price: 500 },
          // { id: 4, name: 'Glamurous', price: 550 },
        ],
      },
    ],
  },

  {
    id: 3,
    image: bridal,
    service: [
      { name: 'Bridal Makeup and Hairstyling' },
      {
        servicesList: [
          // { id: 1, name: 'Classic Manicure', price: 400 },
          // { id: 2, name: 'French Manicure', price: 500 },
          // { id: 3, name: 'Press-On Nails', price: 300 },
          // { id: 4, name: 'Gel Manicure', price: 550 },
        ],
      },
    ],
  },

  {
    id: 4,
    image: occasion,
    service: [
      { name: 'Special Occasion Makeup and Hair' },
      {
        servicesList: [
          // { id: 1, name: 'Blonde', price: 600 },
          // { id: 2, name: 'Highlights', price: 500 },
          // { id: 3, name: 'Color Chart', price: 700 },
          // { id: 4, name: 'Custom', price: 1000 },
        ],
      },
    ],
  },
  {
    id: 5,
    image: fashion,
    service: [
      { name: 'Editorial and Fashion Makeup' },
      {
        servicesList: [
          // { id: 1, name: 'Short Hair Cut', price: 400 },
          // { id: 2, name: 'Long Hair Cut', price: 500 },
          // { id: 3, name: 'Bangs', price: 300 },
          // { id: 4, name: 'Cut + Blow dry', price: 600 },
        ],
      },
    ],
  },

  {
    id: 6,
    image: event,
    service: [
      { name: 'Hairstyling for Events and Photoshoots' },
      {
        servicesList: [
          // { id: 1, name: 'Wedding Make-up', price: 600 },
          // { id: 2, name: 'Make-up & Lashes', price: 450 },
          // { id: 3, name: 'Party Glam', price: 500 },
          // { id: 4, name: 'Glamurous', price: 550 },
        ],
      },
    ],
  },

  {
    id: 7,
    image: effect,
    service: [
      { name: 'Special Effects Makeup' },
      {
        servicesList: [
          // { id: 1, name: 'Classic Manicure', price: 400 },
          // { id: 2, name: 'French Manicure', price: 500 },
          // { id: 3, name: 'Press-On Nails', price: 300 },
          // { id: 4, name: 'Gel Manicure', price: 550 },
        ],
      },
    ],
  },

  {
    id: 8,
    image: workshops,
    service: [
      { name: 'Makeup Lessons and Workshops' },
      {
        servicesList: [
          // { id: 1, name: 'Classic Manicure', price: 400 },
          // { id: 2, name: 'French Manicure', price: 500 },
          // { id: 3, name: 'Press-On Nails', price: 300 },
          // { id: 4, name: 'Gel Manicure', price: 550 },
        ],
      },
    ],
  },
];

export default services;
