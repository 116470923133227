import emailjs from '@emailjs/browser';

const touch =
  'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Ftouch.svg?alt=media&token=07ed8521-1d9e-4eb1-8855-e20aec77d9d0';

function Contact() {
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_jps2a3o',
        'template_8sjjavg',
        e.target,
        '3XFsfU481mQ13RMQW'
      )
      .then(
        () => {
          alert('THANK YOU, WE WILL CONTACT SOON...');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
    e.target.reset();
  }

  return (
    <>
      <div className="contact-us" id="contact">
        {/* <div className="center"> */}
        <div className="contact">
          <h2>Contact Us</h2>
          <form onSubmit={sendEmail}>
            <label htmlFor="Name">Name</label>
            <input type="text" name="user_name" placeholder="Enter Your Name" />

            <label htmlFor="Email">Email</label>
            <input
              type="email"
              name="user_email"
              placeholder="example1@gmail.com"
              required
            />

            <label htmlFor="msg">Message</label>
            <textarea name="message" placeholder="  Hello..."></textarea>

            <input type="submit" value="SUBMIT" />
          </form>
        </div>

        <div className="get-in-touch">
          <h2>get in touch</h2>
          <div className="svgimg">
            <img src={touch} alt="svgimage" />
          </div>
          {/* <div className="info">
            <div className="contact-info">
              <i class="fa-solid fa-location-dot"></i>
              <a
                href="https://maps.app.goo.gl/3XDSkCvRC8zFZKuf9"
                target="_blank"
                rel="noreferrer"
              >
                Xyz.pvt, Gymkhana, Pune 412098
              </a>
            </div>
            <div className="contact-info">
              <i class="fa-solid fa-envelope"></i>
              <a href="mailto:sainotechno1@gamil.com">sainotechno1@gamil.com</a>
            </div>
            <div className="contact-info">
              <i class="fa-solid fa-mobile-button"></i>
              <a href="tel:+9876543210">+91 9876543210</a>
            </div>
          </div> */}
          <h2 className="contact-social">SOCIAL MEDIA</h2>
          <div className="contact-social-icons">
            <a
              href="https://www.instagram.com/pinkylohar_/?igsh=MWxtbnc2djJubnF5eQ%3D%3D&utm_source=qr"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-square-instagram"></i>
            </a>
            {/* <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-square-twitter"></i> */}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default Contact;
