// import React, { useEffect, useState } from 'react';
import './App.css';
// import Login from './components/Login/Login';
import Main from './components/Main/Main.js';
import { Routes, Route } from 'react-router-dom';

function App() {
  //   const [isAdmin, setIsAdmin] = useState(false);
  //   const [userName, setUserName] = useState("");
  //   const [password, setPassword] = useState("");

  // useEffect(() => {
  //     allowAccessToCertainUser()
  //   }, [userName, password])
  //   const allowAccessToCertainUser = () => {
  //     console.log("TRUEUEUE")
  //     if ((userName.toLowerCase() === "bhanu" && password=== "S@in0S0l")
  //       || (userName.toLowerCase() === "danu" && password === "S@in0S0l")
  //     || (userName.toLowerCase() === "jeevan" && password === "S@in0S0l")) {
  //       console.log("TRUEUEUE")
  //       setIsAdmin(true)
  //     }
  //     else {
  //       setIsAdmin(false)
  //     }
  //   }
  //   console.log(userName)

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/main" element={<Main />} />
        {/* <Route path="/main" element={isAdmin ? <Main setIsAdmin={setIsAdmin} setPassword={setPassword} /> : <Login isAdmin={isAdmin} setIsAdmin={setIsAdmin} userName={userName} setUserName={setUserName} password={password} setPassword={setPassword} />} /> */}
        <Route path="/home" element={<Main />} />
      </Routes>
    </div>
  );
}

export default App;
