import TestimonialsCard from './TestimonialsCard';
import priya from '../../image/testimonials/priya.jpeg';
import shamika from '../../image/testimonials/shamika.jpeg';
import angeline from '../../image/testimonials/angeline.jpeg';
// import { useState } from 'react';

// Image from Firebase storage
// const amar =
//   'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Famar.jpg?alt=media&token=ce3106ce-f445-4462-840f-fad6198d5aae';

// const kay =
//   'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Fkay.jpg?alt=media&token=b32e8308-5d97-4cd6-b932-c867abe3a31e';

// const mick =
//   'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Fmick.jpg?alt=media&token=980fbafb-fb4c-47aa-8aba-d1d507f9f951';

// const nick =
//   'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Fnick.jpg?alt=media&token=0729598f-f3bb-417b-b371-c169b81013ae';

// const rin =
//   'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Frin.jpg?alt=media&token=2dea6467-a386-425c-b1f6-4f5e4ead6d18';

// const sara =
//   'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Fsara.jpg?alt=media&token=4b3a9439-3e24-45c7-824a-1dff1f31bdd4';

function Testimonials() {
  const objectData = [
    {
      image: priya,
      name: 'Priya',
      subject: 'Super professional',
      review:
        'You can always trust Pinky and her team when it comes to getting ready for any event be it traditional or modern. She enhances your features but always keeps it classy and minimal. I love every single look we’ve done. Super professional , dedicated and fun to work with !!',
    },
    {
      image: shamika,
      name: 'Shamika Reddy',
      subject: 'Flawless',
      review:
        "I recently had the pleasure of working with Pinky, a highly skilled and talented makeup artist, for a special event. My experience was exceptional, and I can't recommend her services enough.From the moment I met Pinky, I was impressed by her friendly demeanor and attention to detail. She took the time to understand my vision and preferences, asking thoughtful questions to ensure she created a look that would suit me perfectly.Pinky's artistry is truly remarkable. She flawlessly executed the makeup look I wanted, enhancing my features while keeping the overall look natural and elegant. Her techniques are precise and meticulous, and she uses high-quality products that ensured my makeup stayed fresh and flawless throughout the entire event.Overall, Pinky and her team exceeded my expectations in every way. I can't wait to work with them again in the future!",
    },
    {
      image: angeline,
      name: 'Angeline',
      subject: 'Skilled',
      review:
        "Pinky's makeup artistry is pure magic, turning faces into works of art with finesse. Her personalized approach ensures every client leaves feeling confident and radiant. With a keen eye for detail and a gentle touch, she creates stunning looks for any occasion. From subtle enhancements to dramatic transformations, Pinky's talent knows no bounds. Trust her with your beauty needs, and prepare to be amazed.",
    },
    // {
    //   image: mick,
    //   name: 'mick',
    //   subject: 'Skilled',
    //   review:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit.temporibus error et, placeat voluptates iure ab porro aspernatur, magnam tempore eius veritatis! Tempora velit sit aperiam quis.',
    // },
    // {
    //   image: nick,
    //   name: 'rin',
    //   subject: 'Skilled',
    //   review:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit.temporibus error et, placeat voluptates iure ab porro aspernatur, magnam tempore eius veritatis! Tempora velit sit aperiam quis.',
    // },
    // {
    //   image: sara,
    //   name: 'sara',
    //   subject: 'Skilled',
    //   review:
    //     'Lorem ipsum dolor sit amet consectetur adipisicing elit.temporibus error et, placeat voluptates iure ab porro aspernatur, magnam tempore eius veritatis! Tempora velit sit aperiam quis.',
    // },
  ];

  // const [btnvalue, setbtnvalue] = useState(0);

  // forward crausel
  // function rscroll() {
  //   setbtnvalue(1020);

  //   // for tablet
  //   if (window.innerWidth <= 1200) {
  //     setbtnvalue(920);
  //   }

  //   // for small tablet
  //   if (window.innerWidth <= 768) {
  //     if (btnvalue === 0) {
  //       setbtnvalue(560);
  //     }
  //     if (btnvalue === 560) {
  //       setbtnvalue(1120);
  //     }
  //     if (btnvalue === 1120) {
  //       setbtnvalue(1120);
  //     }
  //   }

  //   // for small tablet
  //   if (window.innerWidth <= 564) {
  //     if (btnvalue === 0) {
  //       setbtnvalue(280);
  //     }
  //     if (btnvalue === 280) {
  //       setbtnvalue(560);
  //     }
  //     if (btnvalue === 560) {
  //       setbtnvalue(840);
  //     }
  //     if (btnvalue === 840) {
  //       setbtnvalue(1120);
  //     }
  //     if (btnvalue === 1120) {
  //       setbtnvalue(1400);
  //     }
  //     if (btnvalue === 1400) {
  //       setbtnvalue(1400);
  //     }
  //   }
  // }

  //  Backword function

  // function lscroll() {
  //   setbtnvalue(0);

  //   if (window.innerWidth <= 768) {
  //     if (btnvalue === 560) {
  //       setbtnvalue(0);
  //     }
  //     if (btnvalue === 1120) {
  //       setbtnvalue(560);
  //     }
  //   }

  //   if (window.innerWidth <= 564) {
  //     if (btnvalue === 1400) {
  //       setbtnvalue(1120);
  //     }

  //     if (btnvalue === 1120) {
  //       setbtnvalue(840);
  //     }

  //     if (btnvalue === 840) {
  //       setbtnvalue(560);
  //     }

  //     if (btnvalue === 560) {
  //       setbtnvalue(280);
  //     }

  //     if (btnvalue === 280) {
  //       setbtnvalue(0);
  //     }
  //   }
  // }

  return (
    <section className="testimonials" id="testimonials">
      <div className="testimonials-heading">
        <h1>Testimonials </h1>
      </div>
      <div className="testimonials-container">
        {/* <div className="testimonials-left-arrow">
          <p onClick={lscroll}> &lt;</p>
        </div> */}
        <div className="testimonials-slider">
          <TestimonialsCard objectData={objectData} />
          {/* //this value in side testimonial card for crausel  /// value={btnvalue} */}
        </div>
        {/* <div className="testimonials-right-arrow">
          <p onClick={rscroll}>&gt;</p>
        </div> */}
      </div>
    </section>
  );
}

export default Testimonials;
