import { IoSend } from 'react-icons/io5';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const footerlogo =
  'https://firebasestorage.googleapis.com/v0/b/saloonweb-ad3f5.appspot.com/o/Saloon-Images%2Fpl-white.png?alt=media&token=29411204-35ec-4964-8e50-8d861894638d';

function Footer() {
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_jps2a3o',
        'template_017x5vd',
        e.target,
        '3XFsfU481mQ13RMQW'
      )
      .then(
        () => {
          alert('THANK YOU SO MUCH FOR SUBSCRIBE 🤗🎊🎉✨');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
    e.target.reset();
  }
  const navigateToTop = useNavigate()
  const handleClickTop = (e) =>{
    return navigateToTop("/")
  }
  return (
    <>
      <div className="footer">
        <div className="footer-upper">
          {/* pinky-lohar */}
          <div className="pinky-lohar">
            <div className="footer-logo" onClick={(e)=>handleClickTop(e)}>
              {/* <a href="#header"> */}
              <img src={footerlogo} alt="footer-logo" />
              {/* </a> */}
            </div>
            {/* <div className="footer-info">
              <i class="fa-solid fa-location-dot"></i>
              <a
                href="https://maps.app.goo.gl/3XDSkCvRC8zFZKuf9"
                target="_blank"
                rel="noreferrer"
              >
                Xyz.pvt, Gymkhana, Pune 412098
              </a>
            </div>
            <div className="footer-info">
              <i class="fa-solid fa-mobile-button"></i>
              <a href="tel:+9876543210">+91 9876543210</a>
            </div>
            <div className="footer-info">
              <i class="fa-solid fa-envelope"></i>
              <a href="mailto:sainotechno1@gamil.com">sainotechno1@gmail.com</a>
            </div> */}
          </div>

          {/* company */}
          <div className="company">
            <h3>company</h3>
            <a href="#about">about me</a>
            <a href="#testimonials">Testimonial</a>
            <a href="#services">Services</a>
            <a href="#about">About</a>
            <a href="#milestone">Milestone</a>
            <a href="#contact">Contact</a>
          </div>

          {/* follow us */}
          <div className="follow-us">
            <h3>follow us</h3>
            <div className="footer-social">
              <a
                href="https://www.instagram.com/pinkylohar_/?igsh=MWxtbnc2djJubnF5eQ%3D%3D&utm_source=qr"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa-brands fa-square-instagram"></i>
              </a>
              {/* <i class="fa-brands fa-facebook"></i>
              <i class="fa-brands fa-square-twitter"></i> */}
            </div>
          </div>

          {/* subscribe */}
          <div className="subscribe">
            <h3>subscribe</h3>
            <p>Subscribe now for exclusive updates and special offers.</p>
            <form onSubmit={sendEmail}>
              <div className="subscribe-bar">
                <input
                  type="email"
                  name="user_email"
                  placeholder="Email"
                  required
                />
                <button>
                  subscribe <IoSend size={25} />
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* copyright */}
        <div className="footer-lower">
          <p>
            Copyright © 2024 Designed and Developed with ❤ By Saino Solutions
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
